import { makeQuery } from '@/api/helpers/makeQuery'

const query = `
mutation Login($email: String!, $password: String!) {
  users {
    login(email: $email, password: $password) {
      accessToken
      refreshToken
    }
  }
}`

async function login(email, password) {
  return makeQuery({
    query,
    variables: { email, password },
    success: data => data.users.login,
  })
}

export default login
